import React, { useState } from 'react';
import axios from 'axios';
import { useLoading } from './Loading';
import { useCampaignId } from './CampaignIdContext';
import DownloadCSVButton from './DownloadCSV';

const apiUrl = process.env.REACT_APP_API_URL;

const SendDataButton = ({ points, setPredictedPoints, details }) => {
  const { setLoading } = useLoading();
  const [showMenu, setShowMenu] = useState(false); // State to control the visibility of the dropdown menu
  const [standardDeviation, setStandardDeviation] = useState(details.stDev);
  const campaignId = useCampaignId(); // State to store selected campaign ID
  const [downloadableData, setDownloadableData] = useState(null);

  const sendDataToBackend = async () => {
    setLoading(true);
    setShowMenu(false);
    try {
      const sampledPoints = points.filter((_, index) => index % 1 === 0);
      const payload = {
        campaignId: campaignId.campaignId,
        data: sampledPoints,
        standardDeviation: parseFloat(standardDeviation),
      };
      const response = await axios.post(`${apiUrl}/api/store-data`, payload);

      if (response.data && Array.isArray(response.data)) {
        const newPredictedPoints = response.data.map(d => ({ x: d[0], y: d[1], stdDev: d[2] }));
        console.log(newPredictedPoints);
        setPredictedPoints(newPredictedPoints);
        setDownloadableData(newPredictedPoints);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
      <div className="button-section">
          <button className="run-model-button btn green" onClick={() => setShowMenu(true)}>
              Run Model
          </button>
          {showMenu && (
              <div className="dialogue-container">
                  <div className="dialogue">
                      <label style={{ color: "white" }}>
                          Standard Deviations:
                          <input
                              type="number"
                              step="any"
                              min="0"
                              value={standardDeviation}
                              onChange={(e) => setStandardDeviation(Math.max(0, e.target.value))}
                          />
                      </label>
                      <button className="btn green" onClick={sendDataToBackend} disabled={!campaignId}>
                          Confirm and Run
                      </button>
                      <button onClick={() => setShowMenu(false)}>Cancel</button> {/* Allow user to close the menu without making a selection */}
                  </div>
              </div>
          )}
          <DownloadCSVButton data={downloadableData} />
      </div>
  );
};

export default SendDataButton;

