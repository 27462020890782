const handleCanvasClick = (xClicked, yClicked, setPoints, range, margin, plottingAreaWidth, plottingAreaHeight, details) => {

    const xValue = details.domainMin + ((xClicked - margin.LEFT) / plottingAreaWidth) * (details.domainMax - details.domainMin);
    const yValue = range.min + (yClicked / plottingAreaHeight) * (range.max - range.min) - 0.08 * range.max;
    console.log(xValue, yValue)
    if (xValue < details.minx) {
        return;
    }
    if (details.maxx !== '' && xValue > details.maxx) {
        return;
    }
    setPoints((prev) => [...prev, { x: xValue, y: yValue }]);
};

export default handleCanvasClick;
