const handleMouseMove = (e, range, margin, plottingAreaWidth, plottingAreaHeight, svgHeight, selectedPointIndex, setPoints, details, setZone) => {
    const boundingRect = e.currentTarget.getBoundingClientRect();
    const xMouse = e.clientX - boundingRect.left;
    const yMouse = e.clientY - boundingRect.top;
    const xValue = details.domainMin + (xMouse - margin.LEFT) / plottingAreaWidth * (details.domainMax - details.domainMin);
    const yValue = range.min + (svgHeight - yMouse) / plottingAreaHeight * (range.max - range.min) - (0.08*range.max);
    setZone(false);
    if (xValue < details.minx) {
      setZone(true);
      return;
    }
    if (details.maxx !== '' && xValue > details.maxx) {
      setZone(true);
      return;
    }
    if (selectedPointIndex !== null) {
      setPoints(prevPoints => {
        const newPoints = [...prevPoints];
        newPoints[selectedPointIndex] = { x: xValue, y: yValue };
        return newPoints;
      });
    }
  };
export default handleMouseMove;
