import React, { useState } from "react";
import "./App.css";
import "./index.css";
import { LoadingProvider } from "./components/Loading";
import { CampaignIdProvider } from "./components/CampaignIdContext";
import Home from "./components/Home";

function App() {
    const [page, setPage] = useState("");
    return (
        <CampaignIdProvider>
            <LoadingProvider>
                <div className="gaussian">
                    {page !== "" && (
                        <Home pageState={[page, setPage]} />
                    )}
                    {page === "" && (
                        <div className="container split">
                            <h1>Choose a Reactor:</h1>
                            <div onClick={() => setPage("JET")}>
                                <h2>JET</h2>
                                <img src="/images/models/JET.png" alt="jet tokamak" />
                            </div>
                            <div onClick={() => setPage("MAST-U")}>
                                <h2>MAST-U</h2>
                                <img src="/images/models/MAST.jpg" alt="mask tokamak" />
                            </div>
                        </div>
                    )}
                </div>
            </LoadingProvider>
        </CampaignIdProvider>
    );
}

export default App;
