export const xToPixel = (xValue, domainMin, domainMax, marginLeft, plottingAreaWidth) => {
    return marginLeft + (xValue - domainMin) / (domainMax - domainMin) * plottingAreaWidth;
  };
  
  
export const yToPixel = (yValue, rangeMin, rangeMax, svgHeight, marginBottom, plottingAreaHeight) => {
    return svgHeight - marginBottom - (yValue - rangeMin) / (rangeMax - rangeMin) * plottingAreaHeight;
  };

export const y2ToPixel = (yValue, rangeMin, rangeMax, svgHeight, marginBottom, plottingAreaHeight) => {
  return svgHeight - marginBottom - (yValue - rangeMin) / (rangeMax - rangeMin) * plottingAreaHeight;
};

export const calculateIntervals = (min, max) => {
    const interval = (max - min) / 10;
    let values = [];
    for (let i = 0; i <= 10; i ++) {
      values.push(min + interval * i);
    }
    return values;
  };
  

 export function gaussianNoise(mean, standardDeviation) {
    const u1 = Math.random();
    const u2 = Math.random();
    const randStdNormal = Math.sqrt(-2.0 * Math.log(u1)) * Math.sin(2.0 * Math.PI * u2);
    return mean + standardDeviation * randStdNormal;
  }