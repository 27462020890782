import React from "react";
import Graph from "./Graph";
import { useCampaignId } from "./CampaignIdContext";

function Home({ pageState }) {
    const { setCampaignId } = useCampaignId();
    const [page, setPage] = pageState;
    return (
        <div className="container">
            <div
                className="home"
                onClick={() => {
                    setPage("");
                    setCampaignId("");
                }}
            >
                &#8249; Go home
            </div>
            <h1>{page}</h1>
            <div className="side-by-side">
                <Graph model={page} />
            </div>
        </div>
    );
}

export default Home;