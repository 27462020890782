import React, { useState, useEffect } from "react";

import Axis from "./Axis";
import Input from "./FunctionInput";
import FileUpload from "./FileUpload";
import SendDataButton from "./SendDataButton";
import handleMouseMove from "./MouseMoveHandler";
import handleCanvasClick from "./CanvasClickHandler";
import { xToPixel, yToPixel, calculateIntervals, y2ToPixel } from "../utils/utils";
import { useCampaignId } from "./CampaignIdContext";
import { useLoading } from "./Loading";
import { campaignDetails, defaultDetails } from "../data/campaign";

const MARGIN = {
    LEFT: 120,
    RIGHT: 120,
    TOP: 40,
    BOTTOM: 40,
};
const SVG_WIDTH = 860;
const SVG_HEIGHT = SVG_WIDTH * 0.6;
const PLOTTING_AREA_WIDTH = SVG_WIDTH - (MARGIN.LEFT + MARGIN.RIGHT);
const PLOTTING_AREA_HEIGHT = SVG_HEIGHT - (MARGIN.TOP + MARGIN.BOTTOM);

const Graph = (page) => {
    const { loading } = useLoading();
    const { campaignId, setCampaignId } = useCampaignId();
    const details = campaignDetails[campaignId] || defaultDetails;

    const [points, setPoints] = useState([]);
    const [zone, setZone] = useState(false);
    const [predictedPoints, setPredictedPoints] = useState([]);
    const [range, setRange] = useState({ min: details.inputMin, max: details.inputMax });
    const [range2, setRange2] = useState({ min: details.outputMin, max: details.outputMax });
    const [selectedPointIndex, setSelectedPointIndex] = useState(null);
    const [altered, setAltered] = useState(false);
    const [draggingPoint, setDraggingPoint] = useState(null);

    const xIntervals = calculateIntervals(details.domainMin, details.domainMax);
    const yIntervals = calculateIntervals(range.min, range.max);
    const y2Intervals = calculateIntervals(range2.min, range2.max);
    const maxY = range.max;

    useEffect(() => {
        // Update range and range2 based on the current campaignId
        setRange({ min: details.inputMin, max: details.inputMax });
        setRange2({ min: details.outputMin, max: details.outputMax });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [campaignId]); // <--- This ensures the effect runs whenever campaignId changes.

    const drawnPoints = [...points].sort((a, b) => a.x - b.x);
    const handleClick = (e) => {
        if (campaignId === "") {
            alert("Please select a model first");
            return; // Don't do anything if no campaign is selected
        }
        const boundingRect = e.currentTarget.getBoundingClientRect();
        const xClicked = e.clientX - boundingRect.left;
        const yClicked = boundingRect.bottom - e.clientY;
        if (xClicked < MARGIN.LEFT || xClicked > SVG_WIDTH - MARGIN.RIGHT || yClicked < MARGIN.TOP || yClicked > SVG_HEIGHT - MARGIN.BOTTOM) {
            return;
        } else {
            handleCanvasClick(xClicked, yClicked, setPoints, range, MARGIN, PLOTTING_AREA_WIDTH, PLOTTING_AREA_HEIGHT, details);
        }
    };

    const handleMouse = (e) => {
        const boundingRect = e.currentTarget.getBoundingClientRect();
        const xClicked = e.clientX - boundingRect.left;
        const yClicked = boundingRect.bottom - e.clientY;
        if (xClicked < MARGIN.LEFT || xClicked > SVG_WIDTH - MARGIN.RIGHT || yClicked < MARGIN.TOP || yClicked > SVG_HEIGHT - MARGIN.BOTTOM) {
            return;
        } else {
            handleMouseMove(e, range, MARGIN, PLOTTING_AREA_WIDTH, PLOTTING_AREA_HEIGHT, SVG_HEIGHT, selectedPointIndex, setPoints, details, setZone);
        }
    };
    const handleUploadedPoints = (newPoints) => {
        const uploadedPoints = [];
        for (let index = 0; index < newPoints.length; index++) {
            const element = newPoints[index];
            if (element.y > maxY || element.x < details.minx || element.x > xIntervals[10]) {
                setAltered(true);
            } else {
                uploadedPoints.push(element);
            }
        }

        setPoints(uploadedPoints);
    };

    const handlePlot = (evaluateFunction, functionExpression, minX, maxX) => {
        const newPoints = [];
        for (let x = details.minx; x <= maxX; x += (details.domainMax - details.domainMin) / 100) {
            if (x < minX) continue; // Skip points that are not in the range
            const y = evaluateFunction(functionExpression, x);
            if (y !== null && y <= maxY && y >= 0) {
                newPoints.push({ x, y });
            }
        }
        setPoints(newPoints);
    };
    function clearPoints() {
        setPoints([]);
        setPredictedPoints([]);
        // remove uploaded files
        handleUploadedPoints([]);
        const fileInput = document.getElementById("file-upload");
        if (fileInput) {
            fileInput.value = "";
        }
        setAltered(false);
    }

    return (
        <>
            <div className="graph-container">
                <svg
                    className="rounded-svg"
                    width={SVG_WIDTH}
                    height={SVG_HEIGHT}
                    onClick={handleClick}
                    onMouseMove={handleMouse}
                    onMouseUp={() => setSelectedPointIndex(null)}
                >
                    {/* {Axis drawing} */}
                    {Array.from({ length: 10 }).map((_, index) => (
                        <line
                            key={index}
                            x1={MARGIN.LEFT}
                            y1={yToPixel(yIntervals[index], range.min, range.max, SVG_HEIGHT, MARGIN.BOTTOM, PLOTTING_AREA_HEIGHT)}
                            x2={SVG_WIDTH - MARGIN.RIGHT}
                            y2={yToPixel(yIntervals[index], range.min, range.max, SVG_HEIGHT, MARGIN.BOTTOM, PLOTTING_AREA_HEIGHT)}
                            stroke="#e0e0e0"
                        />
                    ))}

                    {Array.from({ length: 10 }).map((_, index) => (
                        <line
                            key={index}
                            x1={xToPixel(xIntervals[index], details.domainMin, details.domainMax, MARGIN.LEFT, PLOTTING_AREA_WIDTH)}
                            y1={MARGIN.TOP}
                            x2={xToPixel(xIntervals[index], details.domainMin, details.domainMax, MARGIN.LEFT, PLOTTING_AREA_WIDTH)}
                            y2={SVG_HEIGHT - MARGIN.BOTTOM}
                            stroke="#e0e0e0"
                        />
                    ))}
                    <Axis
                        xIntervals={xIntervals}
                        yIntervals={yIntervals}
                        yIntervalsRight={y2Intervals}
                        margin={MARGIN}
                        height={SVG_HEIGHT}
                        width={SVG_WIDTH}
                        plottingAreaHeight={PLOTTING_AREA_HEIGHT}
                        plottingAreaWidth={PLOTTING_AREA_WIDTH}
                    />
                    {drawnPoints.map((point, idx) => {
                        if (idx === 0) return null;
                        const prevPoint = drawnPoints[idx - 1];
                        return (
                            <line
                                key={idx}
                                x1={xToPixel(prevPoint.x, details.domainMin, details.domainMax, MARGIN.LEFT, PLOTTING_AREA_WIDTH)}
                                y1={yToPixel(prevPoint.y, range.min, range.max, SVG_HEIGHT, MARGIN.BOTTOM, PLOTTING_AREA_HEIGHT)}
                                x2={xToPixel(point.x, details.domainMin, details.domainMax, MARGIN.LEFT, PLOTTING_AREA_WIDTH)}
                                y2={yToPixel(point.y, range.min, range.max, SVG_HEIGHT, MARGIN.BOTTOM, PLOTTING_AREA_HEIGHT)}
                                stroke={details.inputColor}
                                strokeWidth="2.5"
                            />
                        );
                    })}
                    {predictedPoints.length && (
                        <>
                            <path
                                className="newPoints"
                                d={`
                                M ${predictedPoints
                                    .map(
                                        (p) =>
                                            `${xToPixel(p.x, details.domainMin, details.domainMax, MARGIN.LEFT, PLOTTING_AREA_WIDTH)},${y2ToPixel(
                                                p.y - p.stdDev,
                                                range2.min,
                                                range2.max,
                                                SVG_HEIGHT,
                                                MARGIN.BOTTOM,
                                                PLOTTING_AREA_HEIGHT
                                            )}`
                                    )
                                    .join(" L ")}
                                L ${predictedPoints
                                    .reverse()
                                    .map(
                                        (p) =>
                                            `${xToPixel(p.x, details.domainMin, details.domainMax, MARGIN.LEFT, PLOTTING_AREA_WIDTH)},${y2ToPixel(
                                                p.y + p.stdDev,
                                                range2.min,
                                                range2.max,
                                                SVG_HEIGHT,
                                                MARGIN.BOTTOM,
                                                PLOTTING_AREA_HEIGHT
                                            )}`
                                    )
                                    .join(" L ")} Z`}
                                fill="rgba(110, 231, 183, 0.3)"
                            />
                            <path
                                className="newPoints"
                                d={`
                                    M ${predictedPoints
                                        .map(
                                            (p) =>
                                                `${xToPixel(p.x, details.domainMin, details.domainMax, MARGIN.LEFT, PLOTTING_AREA_WIDTH)},${y2ToPixel(
                                                    p.y,
                                                    range2.min,
                                                    range2.max,
                                                    SVG_HEIGHT,
                                                    MARGIN.BOTTOM,
                                                    PLOTTING_AREA_HEIGHT
                                                )}`
                                        )
                                        .join(" L ")}`}
                                stroke={details.outputColor}
                                strokeWidth="2"
                                fill="none"
                            />
                        </>
                    )}

                    {points.map((point, idx) => (
                        <g key={idx}>
                            <circle
                                cx={xToPixel(point.x, details.domainMin, details.domainMax, MARGIN.LEFT, PLOTTING_AREA_WIDTH)}
                                cy={yToPixel(point.y, range.min, range.max, SVG_HEIGHT, MARGIN.BOTTOM, PLOTTING_AREA_HEIGHT)}
                                r="4"
                                fill={idx === selectedPointIndex ? "red" : "black"} // Change the fill conditionally
                                className={`initialPoints ${idx === selectedPointIndex ? "dragging" : ""}`}
                                onMouseDown={(e) => {
                                    e.preventDefault(); // Prevent default behavior for better drag-and-drop experience
                                    setSelectedPointIndex(idx);
                                }}
                                onMouseOver={(e) => {
                                    if (idx !== selectedPointIndex) {
                                        e.target.setAttribute("fill", "red");
                                        const textElement = e.target.nextElementSibling;
                                        textElement.style.display = "block";
                                    }
                                }}
                                onMouseOut={(e) => {
                                    if (idx !== selectedPointIndex) {
                                        e.target.setAttribute("fill", "black");
                                        const textElement = e.target.nextElementSibling;
                                        textElement.style.display = "none";
                                    }
                                }}
                            />
                            <text
                                x={xToPixel(point.x, details.domainMin, details.domainMax, MARGIN.LEFT, PLOTTING_AREA_WIDTH)}
                                y={yToPixel(point.y, range.min, range.max, SVG_HEIGHT, MARGIN.BOTTOM, PLOTTING_AREA_HEIGHT) - 20}
                                textAnchor="middle"
                                fill="rgba(0,0,0,0.3)"
                                fontSize={14}
                                style={{
                                    display: idx === selectedPointIndex ? "block" : "none", // Show text when selected
                                }}
                            >
                                {`(${point.x.toFixed(3)}, ${point.y.toFixed(3)})`}
                            </text>
                        </g>
                    ))}

                    {zone && (
                        <>
                            <rect
                                className="error-rec"
                                x={MARGIN.LEFT}
                                y={0 + MARGIN.TOP}
                                width={xToPixel(details.minx, details.domainMin, details.domainMax, MARGIN.LEFT, PLOTTING_AREA_WIDTH) - MARGIN.LEFT}
                                height={PLOTTING_AREA_HEIGHT}
                                fill="rgba(158, 16, 6, 0.2)" // Light red fill color with transparency
                            />
                            {details.maxx !== "" && (
                                <rect
                                    className="error-rec"
                                    x={xToPixel(details.maxx, details.domainMin, details.domainMax, MARGIN.LEFT, PLOTTING_AREA_WIDTH)}
                                    y={0 + MARGIN.TOP}
                                    width={
                                        SVG_WIDTH -
                                        xToPixel(details.maxx, details.domainMin, details.domainMax, MARGIN.LEFT, PLOTTING_AREA_WIDTH) -
                                        MARGIN.RIGHT
                                    }
                                    height={PLOTTING_AREA_HEIGHT}
                                    fill="rgba(158, 16, 6, 0.2)" // Light red fill color with transparency
                                />
                            )}
                        </>
                    )}
                </svg>
                {loading && ( // 2. Conditional rendering for spinner
                    <div
                        style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: "rgba(255, 255, 255, 0.8)",
                            zIndex: 1000,
                        }}
                    >
                        <div
                            style={{
                                border: "5px solid rgba(0, 0, 0, 0.1)",
                                width: "40px",
                                height: "40px",
                                borderRadius: "50%",
                                borderLeft: "5px solid black",
                                animation: "spin 1s linear infinite",
                            }}
                        />
                    </div>
                )}
                {campaignId !== "" && (
                    <>
                        <SendDataButton points={points} setPredictedPoints={setPredictedPoints} details={details} />
                        <button
                            className="btn red"
                            onClick={() => {
                                clearPoints();
                            }}
                        >
                            Clear Points
                        </button>
                    </>
                )}
            </div>
            <div className="campaign-details">
                <h3>Model Details</h3>
                <div className="model-chooser">
                    <p>Please select a model:</p>
                    <select
                        value={campaignId} // set the value of the dropdown to either the selected campaignId or the default value
                        onChange={(e) => {
                            setCampaignId(e.target.value);
                            clearPoints();
                        }}
                    >
                        <option value="" disabled>
                            Select a model ID
                        </option>
                        {page.model === "MAST-U" && (
                            <>
                                <option value="mastu-forward">Forward Model</option>
                                <option value="mastu-inverse">Inverse Model</option>
                                {/* <option value="mastu-auto">Auto-predictive Model</option> */}
                            </>
                        )}
                        {page.model === "JET" && (
                            <>
                                <option value="jet-forward">Forward Model</option>
                                <option value="jet-inverse">Inverse Model</option>
                            </>
                        )}
                    </select>
                </div>
                {details.inputs !== "Default Input" && (
                    <div>
                        <p>
                            <b>{details.title}</b>
                        </p>{" "}
                        {/* Directly using the campaignId variable */}
                        <p>
                            <span className="color-indicator" style={{ backgroundColor: details.inputColor }}></span>
                            Inputs: {details.inputs}
                        </p>
                        <p>
                            <span className="color-indicator" style={{ backgroundColor: details.outputColor }}></span>
                            Outputs: {details.outputs}
                        </p>
                    </div>
                )}
            </div>
            {campaignId !== "" && (
                <div className="controls-container">
                    <FileUpload onFileUpload={handleUploadedPoints} campaignId={campaignId} />
                    {altered && <p className="error-message">Some points were altered to fit the model. Please check the graph.</p>}
                    <Input onPlot={handlePlot} minX={details.minx} maxX={xIntervals[10]} />
                </div>
            )}
        </>
    );
};

export default Graph;
