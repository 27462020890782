import React, { useState } from "react";
import * as math from "mathjs";

const Input = ({ onPlot, minX, maxX }) => {
    const [functionExpression, setFunctionExpression] = useState("");
    const [error, setError] = useState(null);
    const [minXValue, setMinXValue] = useState(minX);
    const [maxXValue, setMaxXValue] = useState(maxX);

    const handleFunctionInputChange = (e) => {
        const input = e.target.value;
        setFunctionExpression(input);
        setError(null);
    };

    const plotFunction = () => {
        try {
            onPlot(evaluateFunction, functionExpression, minXValue, maxXValue);
        } catch (error) {
            setError("Invalid expression. Please check your input.");
        }
    };
    let errorLogged = false;
    const evaluateFunction = (expr, xValue) => {
        try {
            const func = math.compile(expr);
            return func.evaluate({ x: xValue });
        } catch (error) {
            if (!errorLogged) {
                alert("Invalid Function, please check your input.");
                errorLogged = true;
            }
            return null;
        }
    };

    return (
        <div className="button-section">
            <div className="function-container">
                <h5>Function</h5>
                <label htmlFor="minX">
                    Min x:
                    <input type="number" name="minX" id="minX" min={minX} max={maxX} defaultValue={minX} step={0.01} onChange={(e)=> setMinXValue(e.target.value)}/>
                </label>
                <label htmlFor="maxX">
                    Max x:
                    <input type="number" name="maxX" id="maxX" min={minX} max={maxX} defaultValue={maxX} step={0.01} onChange={(e)=> setMaxXValue(e.target.value)}/>
                </label>
                <br/>
                <hr/>
                <br/>
                <input type="text" value={functionExpression} onChange={handleFunctionInputChange} placeholder="y=x^3" />
                <button onClick={plotFunction}>Plot</button>
                {error && <p className="error-message">{error}</p>}
            </div>
        </div>
    );
};

export default Input;
