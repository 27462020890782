import React, { createContext, useContext, useState } from 'react';

const CampaignIdContext = createContext();

export function useCampaignId() {
  return useContext(CampaignIdContext);
}

export function CampaignIdProvider({ children }) {
  const [campaignId, setCampaignId] = useState('');

  return (
    <CampaignIdContext.Provider value={{ campaignId, setCampaignId }}>
      {children}
    </CampaignIdContext.Provider>
  );
}
