import React from 'react';

function convertToCSV(data) {
    const headers = ['Inputs', 'Outputs', 'Standard Deviation'];
    const rows = data.map(row => [row.x, row.y, row.stdDev].join(',')); // Extract values and join each row's columns with a comma
    return [headers.join(','), ...rows].join('\n'); // Join all rows with a newline
  }



const DownloadCSVButton = ({ data }) => {
    const csv = data ? convertToCSV(data): '';
    const blob = new Blob([csv], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);

    const downloadFile = () => {
        if (!data) return; // No action if no data
        const link = document.createElement('a');
        link.href = url;
        link.download = 'data.csv';
        link.click();
      };
    
      return (
        <button onClick={downloadFile} disabled={!data} className="button-container btn grey" >
          Download
        </button>
      );
    };

export default DownloadCSVButton;
