import React from 'react';
import { useCampaignId } from './CampaignIdContext';
import { campaignDetails, defaultDetails } from "../data/campaign";
const fontSize = "16px";

const Axis = ({ xIntervals, yIntervals, yIntervalsRight, margin, height, width, plottingAreaHeight, plottingAreaWidth }) => {
  const { campaignId } = useCampaignId();
  const details = campaignDetails[campaignId] || defaultDetails;
  return (
      <>
          {/*Y Axis*/}
          <line x1={margin.LEFT} y1={margin.TOP} x2={margin.LEFT} y2={height - margin.BOTTOM} stroke={details.inputColor} strokeWidth={3} />
          <text
              transform={`rotate(-90)`}
              x={-plottingAreaHeight / 2 - 25} // Adjust as needed
              y={margin.LEFT / 2 - 25} // Adjust as needed
              textAnchor="middle" // Centers the text
              fontSize={fontSize}
          >
              {details ? details.inputs : ""}
          </text>
          {yIntervals.map((value, idx) => (
              <g key={idx}>
                  <line
                      x1={margin.LEFT - 5}
                      y1={height - margin.BOTTOM - idx * (plottingAreaHeight / 10)}
                      x2={margin.LEFT + 5}
                      y2={height - margin.BOTTOM - idx * (plottingAreaHeight / 10)}
                      stroke={details.inputColor}
                  />
                  <text x={margin.LEFT - 60} y={height - margin.BOTTOM - idx * (plottingAreaHeight / 10) + 5} fontSize={fontSize}>
                      {value.toFixed(3)}
                  </text>
              </g>
          ))}

          {/*Y Axis Right*/}
          <line x1={width - margin.RIGHT} y1={margin.TOP} x2={width - margin.RIGHT} y2={height - margin.BOTTOM} stroke={details.outputColor} strokeWidth={3} />
          <text
              transform={`rotate(-90, ${width - margin.RIGHT + 90}, ${height / 2})`} // Adjust these values as needed
              x={width - margin.TOP + 25} // Adjust as needed
              y={height / 2} // Adjust as needed
              textAnchor="middle" // Centers the text
              fontSize={fontSize}
          >
              {details ? details.outputs : ""}
          </text>
          {yIntervalsRight.map((value, idx) => (
              <g key={idx}>
                  <line
                      x1={width - margin.RIGHT - 5}
                      y1={height - margin.BOTTOM - idx * (plottingAreaHeight / 10)}
                      x2={width - margin.RIGHT + 5}
                      y2={height - margin.BOTTOM - idx * (plottingAreaHeight / 10)}
                      stroke={details.outputColor}
                  />
                  <text
                      x={width - margin.RIGHT + 10} // Adjust as needed
                      y={height - margin.BOTTOM - idx * (plottingAreaHeight / 10) + 5}
                      fontSize={fontSize}
                  >
                      {value.toFixed(3)}
                  </text>
              </g>
          ))}

          {/*X Axis*/}
          <line x1={margin.LEFT} y1={height - margin.BOTTOM} x2={width - margin.RIGHT} y2={height - margin.BOTTOM} stroke="black" strokeWidth={3} />
          {xIntervals.map((value, idx) => (
              <g key={idx}>
                  <line
                      x1={margin.LEFT + idx * (plottingAreaWidth / 10)}
                      y1={height - margin.BOTTOM - 5}
                      x2={margin.LEFT + idx * (plottingAreaWidth / 10)}
                      y2={height - margin.BOTTOM + 5}
                      stroke="black"
                  />
                  <text x={margin.LEFT + idx * (plottingAreaWidth / 10) - 30} y={height - margin.BOTTOM + 30} fontSize={fontSize}>
                      {value.toFixed(3)}
                  </text>
              </g>
          ))}
      </>
  );
};

export default Axis;