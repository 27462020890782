const blue = "#009FE3";
const green = "#7DB928";
const grey = "#b6b6af";
const titleGas = "Gas Actuator";
const titleAlpha = "D_alpha";
const titledown = "Downstream Pressure";
const titlewave = "Requested Waveform";

export const campaignDetails = {
    "mastu-forward": {
        title: "Forward Model",
        inputs: titleGas,
        inputColor: blue,
        outputs: titleAlpha,
        outputColor: green,
        inputMax: 160,
        inputMin: 0,
        outputMax: 5,
        outputMin: 0,
        minx: 0.16,
        maxx: "",
        domainMin: 0,
        domainMax: 1.2,
        stDev: 1
    },
    "mastu-inverse": {
        title: "Inverse Model",
        inputs: titleAlpha,
        inputColor: green,
        outputs: titleGas,
        outputColor: blue,
        inputMax: 5,
        inputMin: 0,
        outputMax: 160,
        outputMin: 0,
        minx: 0.16,
        maxx: "",
        domainMin: 0,
        domainMax: 1.2,
        stDev: 1
    },
    "mastu-auto": {
        title: "Auto-predictive Model",
        inputs: titleGas,
        inputColor: blue,
        outputs: titleGas,
        outputColor: blue,
        inputMax: 160,
        inputMin: 0,
        outputMax: 160,
        outputMin: 0,
        minx: 0.16,
        maxx: "",
        domainMin: 0,
        domainMax: 1.2,
        stDev: 1
    },
    "jet-forward": {
        title: "Forward Model",
        inputs: titlewave,
        inputColor: blue,
        outputs: titledown,
        outputColor: green,
        inputMax: 20,
        inputMin: 0,
        outputMax: 0.045,
        outputMin: -0.025,
        minx: 40,
        maxx: 70,
        domainMin: 35.012,
        domainMax: 84.14,
        stDev: 0.5
    },
    "jet-inverse": {
        title: "Inverse Model",
        inputs: titledown,
        inputColor: green,
        outputs: titlewave,
        outputColor: blue,
        inputMax: 0.045,
        inputMin: -0.025,
        outputMax: 20,
        outputMin: 0,
        minx: 40,
        maxx: 70,
        domainMin: 35.012,
        domainMax: 84.14,
        stDev: 0.5
    },
};

export const defaultDetails = {
    inputs: "Default Input",
    inputColor: grey,
    outputs: "Default Output",
    outputColor: grey,
    inputMax: 5,
    inputMin: 0,
    outputMax: 160,
    outputMin: 0,
    minx: 0,
    maxx: "",
    domainMin: 0,
    domainMax: 1.2,
    stDev: 1
};