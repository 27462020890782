import React, {useState} from "react";

const FileUpload = ({ onFileUpload }) => {
    const [haveFile, setHaveFile] = useState(false);
    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            setHaveFile(true);
            const reader = new FileReader();
            reader.onload = (e) => {
                const content = e.target.result;
                const rows = content.split("\n");
                const newPoints = rows
                    .map((row) => {
                        const [x, y] = row.split(/[,\s;]/).map((num) => parseFloat(num));
                        if (isNaN(x) || isNaN(y)) {
                            return null; // Skip rows with NaN values
                        }

                        return { x, y };
                    })
                    .filter((point) => point !== null);
                if (newPoints.length < 1) {
                    alert("No rows found, please check your file");
                    return;
                }
                onFileUpload(newPoints);
            };
            reader.readAsText(file);
        }
    };
    const handleFileRemove = () => {
        const fileInput = document.getElementById("file-upload");
        fileInput.value = "";
        onFileUpload([]);
        setHaveFile(false);
    };

    return (
        <div className="button-section">
            <label htmlFor="file-upload" className="file-upload-button">
                Upload CSV
                <input type="file" id="file-upload" onChange={handleFileUpload} accept=".csv" />
            </label>
            {haveFile && (
                <button className="btn red" onClick={handleFileRemove}>
                    Remove
                </button>
            )}
        </div>
    );
};

export default FileUpload;
